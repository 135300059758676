$secondary: #2f2f2f;

.social-icons-wrapper {
  display: flex;
  max-width: 850px;
  margin: auto;
  justify-content: center;
  font-size: 4.5rem;
  flex-wrap: nowrap;
  padding: 30px;
  margin-top: -85px;

  @media screen and (max-width: 640px) and (min-width: 320px) {
    flex-wrap: wrap;
    font-size: 3.2rem;
    margin-top: -60px;
  }

  .icon {
    margin-left: 10px;

    color: $secondary;

    &:hover {
      color: #505050;
    }
  }
}

.section {
  background-color: #f7f7f7;
}
