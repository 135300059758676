$primary: #000;
$secondary: #2f2f2f;

.container-wrapper {
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .section-title {
    margin-top: -55px;
    text-align: center;
    max-width: 900px;
    font-size: 2.5rem;

    @media screen and (max-width: 640px) and (min-width: 320px) {
      margin-top: 25px;
      font-size: 1.4rem;
      text-align: center;
    }
  }

  .section-img-wrapper {
    .section-img {
      margin-top: -35px;

      width: 175px;
      border-radius: 50%;
      box-shadow: 2px 1px 3px rgb(141, 141, 141);
    }
  }

  .text {
    text-align: center;
    font-size: 1.7rem;
    max-width: 850px;
    color: $secondary;

    @media screen and (max-width: 640px) and (min-width: 320px) {
      font-size: 1.2rem;
      padding: 0 20px;
    }
  }

  .mail {
    margin-top: -50px;
    font-size: 1.9rem;

    &:link,
    :visited {
      text-decoration: none;
      color: $secondary;
    }
    @media screen and (max-width: 640px) and (min-width: 320px) {
      font-size: 1.2rem;
      margin-top: -50px;
    }
  }
}
