@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&family=Roboto:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: "Poppins", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #2f2f2f;
}

a:visited,
:active {
  color: #2f2f2f;
}

.social-icons-wrapper{display:flex;max-width:850px;margin:auto;justify-content:center;font-size:4.5rem;flex-wrap:nowrap;padding:30px;margin-top:-85px}@media screen and (max-width: 640px) and (min-width: 320px){.social-icons-wrapper{flex-wrap:wrap;font-size:3.2rem;margin-top:-60px}}.social-icons-wrapper .icon{margin-left:10px;color:#2f2f2f}.social-icons-wrapper .icon:hover{color:#505050}.section{background-color:#f7f7f7}

.item-wrapper{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;flex-wrap:wrap}.item-wrapper .title{text-transform:uppercase;font-size:1.5rem;font-weight:bold}@media screen and (max-width: 640px) and (min-width: 320px){.item-wrapper .title{font-size:0.9rem;margin-bottom:30px}}.item-wrapper .github-link{margin-top:-30px;font-size:2rem}@media screen and (max-width: 640px) and (min-width: 320px){.item-wrapper .github-link{font-size:1.5rem}}.item-wrapper .thumbnail{box-shadow:1px 1px 6px #eee;transition:ease-in-out 300ms;max-width:550px;margin:0 25px}@media screen and (max-width: 640px) and (min-width: 320px){.item-wrapper .thumbnail{max-width:235px}}.item-wrapper .thumbnail:hover{transform:scale(1.05)}

.wrapper{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}

.container-wrapper{padding:20px;margin:auto;display:flex;flex-direction:column;justify-content:center;align-items:center}.container-wrapper .section-title{margin-top:-55px;text-align:center;max-width:900px;font-size:2.5rem}@media screen and (max-width: 640px) and (min-width: 320px){.container-wrapper .section-title{margin-top:25px;font-size:1.4rem;text-align:center}}.container-wrapper .section-img-wrapper .section-img{margin-top:-35px;width:175px;border-radius:50%;box-shadow:2px 1px 3px #8d8d8d}.container-wrapper .text{text-align:center;font-size:1.7rem;max-width:850px;color:#2f2f2f}@media screen and (max-width: 640px) and (min-width: 320px){.container-wrapper .text{font-size:1.2rem;padding:0 20px}}.container-wrapper .mail{margin-top:-50px;font-size:1.9rem}.container-wrapper .mail:link,.container-wrapper .mail :visited{text-decoration:none;color:#2f2f2f}@media screen and (max-width: 640px) and (min-width: 320px){.container-wrapper .mail{font-size:1.2rem;margin-top:-50px}}

